.protect-text {
  display: flex;
  align-items: center;
  gap: 16px;
 .hide-code {
    border-radius: 4px;
    background-color: #D1E3FF;
    color: #D1E3FF;
 }
  svg {
   color: #2F7DF6;
  }
}
