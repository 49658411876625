.verification-code-form-wrapper {
  .timer {
    color: #1677ff;
    font-weight: 500;
  }

  .resend-btn {
    font-size: 16px;
    font-weight: 500;
  }

  .return-back {
    margin-top: 0 !important;
  }
}
