.eiq-connect-auth-form-wrapper {
  .recaptcha {
    margin-top: 15px;
    > div {
      display: flex;
      justify-content: center;
    }
  }

  .font-size-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    Select {
      width: 90px;
    }
  }

  .action-line {
    padding: 0 35px;
  }

  .forgot-password-text {
    margin-left: auto;
  }

  .keep-me-logged-in {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
    margin: 12px;

    span {
      font: normal normal 400 16px/24px 'Work Sans', sans-serif;
      color: #505762;
      letter-spacing: 0.269px;
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;

      &::after {
        width: 9px;
        height: 14px;
      }
    }
    .ant-checkbox {
      font-size: 24px;
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;

    .title {
      &:hover {
        cursor: pointer;
      }
      &.active {
        &:hover {
          cursor: default;
        }
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 375px) {
    .footer {
      font: normal normal 400 14px/24px 'Work Sans', sans-serif;
    }

    .title-wrapper {
      .title {
        font: normal normal 600 22px/32px 'Work Sans', sans-serif;
      }
    }
  }
}
