.eiq-connect-add-edit {
    .eiq-content {
        .ant-form {
            width: 100%;
            height: 100%;

            .ant-col {
                flex-grow: 1;
            }
        }

        .eiq-connect-block {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }

        .eiq-connect-company-block {
            .eiq-input {
                width: 100%;
            }
        }
    }

    .ant-form {
        height: 100vh;
        width: 100%;

        .ant-col {
            flex-grow: 1;
        }
    }

    .eiq-content {
        gap: 0 !important;
        width: 100%;
        height: calc(100vh - 76px);
        overflow-y: auto;

        .ant-space {
            align-items: initial;
            gap: 24px !important;
        }

        .eiq-date-picker {
            display: flex;
            flex-direction: column;
            gap: 0 !important;
        }

        .eiq-date-range-block {
            display: flex;
            gap: 24px;
        }


        .ant-picker {
            padding: 0;
        }

        .ant-picker-input {
            height: 44px;

            input {
                height: 40px;
            }
        }

        .ant-checkbox-group {
            gap: 8px;
        }

        .eiq-input {
            width: calc(50% - 12px);
        }

        .ant-btn {
            color: #E86056;
            padding: 0;
        }
    }
}

@media (max-width: 500px) {
    .eiq-connect-add-edit {
        .eiq-content {

            .ant-space {
                flex-wrap: wrap;
                gap: 0px !important;
            }

            .ant-divider {
                margin: 20px 0;
            }

            .eiq-input {
                width: 100%;
            }

            .eiq-date-range {
                min-height: 180px;
                height: 100%;
            }

            .eiq-date-picker {
                height: 100%;
            }

            .eiq-connect-company-block {
                width: 100%;
            }
        }
    }
}
