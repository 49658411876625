.eiq-select-address {
  width: 40%;
  min-width: 255px;

  .ant-select-selector {
    border-radius: 16px;
    height: 46px;
    border: 1px solid #AEB5BE;
    background: #F6F8FA;
  }

  @media screen and (max-width: 992px){
    max-width: unset;
    width: 100%;
    margin-bottom: 16px;
  }
}
