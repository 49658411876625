.editNotificationsBlock {
    padding-left: 36px;

    span {
        font: normal normal 400 14px/18px 'Work Sans', sans-serif !important;
    }

    .phoneNumber {
        display: flex;
        align-items: baseline;
        gap: 13px;
    }

    .button {
        width: 159px;
    }

    .disabled {
        opacity: 0.5;
    }
}