.hotkey-input {
  font: normal normal 400 12px/14px 'Work Sans';
  &__label {
    color: #292B2E;
  }

  .ant-input-prefix {
    cursor: pointer;
    margin-right: 8px;
    user-select: none;
    :active {
      transform: scale(1.01);
    }
  }
}
