.eiq-unsubscribe-page {
  display: flex;
  justify-content: center;
  height: 100dvh;
  overflow: hidden;

  .eiq-wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 25px;

    .eiq-content {
      padding-inline: 20px;
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;

      flex-direction: column;
      gap: 10px;

      .logo-wrapper {
        display: flex;
        justify-content: center;

        .logo {
          max-width: 250px;
          width: 100%;
        }
      }

      p {
        margin: 0;
        font: normal normal 400 24px/26px 'Work Sans', sans-serif;
        color: #292b2e;
      }

      .unsubscribe-btn {
        min-width: 300px;
        min-height: 45px;
        margin: 30px 0;
        font: normal normal 400 18px/26px 'Work Sans', sans-serif;
      }
    }
  }
}
