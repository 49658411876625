.add-to-wallet-container {
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  .add-to-wallet-btn {
    height: 46px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font: normal normal 500 22px / 26px "Work Sans", sans-serif;
    padding: 2px 16px;
    border-radius: 15px;
    max-width: 556px;
    width: 100%;
  }
}