.eiq-filter-page {
  height: 100vh;
  .checkbox-all-group,
  .ant-radio-group,
  .ant-checkbox-group {
    gap: 16px !important;
  }

  .ant-radio-group label {
    font: normal normal 400 16px/24px 'Work Sans', sans-serif !important;
  }

  .range-date__content {
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }

  .ant-picker {
    width: 100%;
  }

  .eiq-divider {
    margin: 24px 0 !important;
  }

  .ant-form-item {
    &-label {
      max-height: none !important;
      margin-bottom: 8px;
    }
  }
}
