.model-iphone {
  .eiq-account {
    height: calc(100vh - 272px) !important;
  }
}

.eiq-account {
  gap: 18px !important;
  display: flex;
  flex-direction: row !important;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 232px);

  @media screen and (min-width: 992px) {
    height: 100% !important;
    overflow: unset !important;
  }

  .info-field-lable {
    font: normal normal 400 16px/18px 'Work Sans', sans-serif;
    color: #292b2e;
  }
  .info-field-value {
    font: normal normal 500 18px/22px 'Work Sans', sans-serif !important;
  }
  .eiq-account-user-info-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .eiq-user-name {
      font: normal normal 500 20px/24px 'Work Sans', sans-serif;
      color: #292b2e;
      margin: 0;
    }
    .eiq-community {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .eiq-account-qr-code-wrapper {
      .qr-code-content,
      .qr-code-body,
      .qr-code-form {
        display: flex;
        flex-direction: column;
      }

      .qr-code-body,
      .qr-code-form {
        gap: 16px;
        align-items: center;
      }

      .qr-code-content {
        gap: 24px;

        .qr-code-border {
          background-image: url('../../../../assets/icons/eiq-connect/qr-code-frame.png');
          background-size: cover;
          width: 216px;
          height: 216px;
          padding: 8px;
        }

        .qr-code-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font: normal normal 400 16px/24px 'Work Sans', sans-serif;
          letter-spacing: 0.0168em;
          text-align: center;
          margin-bottom: 10px;
        }
      }

      .qr-code-address-select {
        width: 100%;

        .ant-select-selector {
          height: 46px;
          padding: 2px 20px 2px 16px;
          border-radius: 16px;
          background: #f6f8fa;
          color: #505762;
          font: normal normal 400 16px/24px 'Work Sans', sans-serif;
          letter-spacing: 0.269px;

          .ant-select-selection-item {
            padding: 5px 0 0 12px;
          }

          .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
          }
        }

        @media screen and (max-width: 992px) {
          max-width: unset;
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }

  .eiq-account-info-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;

    .eiq-account-phones,
    .eiq-account-info-block {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .eiq-link {
        font: normal normal 500 18px/22px 'Work Sans', sans-serif !important;
        color: #2f7df6;
        cursor: pointer;
      }
    }

    .eiq-account-phones {
      gap: 12px;

      .contact-phone {
        display: flex;
        gap: 4px;
        align-items: center;
        color: #292b2e;
        font: normal normal 500 18px/22px 'Work Sans', sans-serif;
      }
    }

    .eiq-label {
      font: normal normal 400 16px/24px 'Work Sans', sans-serif;
    }

    .account-divider {
      margin: 16px 0;
    }

    .eiq-account-notification {
      .info-field {
        min-height: initial;
      }
    }
  }

  @media (max-width: 500px) {
    .eiq-account-info-container {
      flex-direction: column;
    }
  }
}

@media (max-width: 920px) {
  .eiq-account {
    flex-direction: column !important;
  }
}
