.eiq-image-gallery {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;

  .image-gallery {
    width: 480px;

    .eiq-image-gallery-image {
      max-width: 480px;
      height: 340px;
      @media screen and (max-width: 480px) {
        height: 220px;
      }

      img {
        border-radius: 12px;
        border: 1px solid #2e79ef;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .eiq-image-gallery-thumbnail-image {
      transition: none !important;
      cursor: pointer;

      .image-gallery-thumbnail-inner {
        height: 66px;
      }

      img {
        border-radius: 12px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.active {
        border: 2px solid #2e79ef;
        border-radius: 13px;
      }

      .image-gallery-thumbnail:hover {
        border: 2px solid #2e79ef !important;
        border-radius: 13px !important;
      }
    }

    .eiq-image-gallery-thumbnail-image:hover {
      border: 2px solid #2e79ef !important;
      border-radius: 13px !important;
    }

    .left-nav {
      position: absolute !important;
      left: -50px;
      top: 50%;
      width: 40px !important;
      height: 40px !important;
      border-radius: 20px;
    }

    .right-nav {
      position: absolute !important;
      right: -50px;
      top: 50%;
      width: 40px !important;
      height: 40px !important;
      border-radius: 20px;
    }

    .image-gallery-bullets {
      bottom: -15px !important;

      .image-gallery-bullets-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .image-gallery-bullet {
          box-shadow: none;
          background-color: #d1e3ff;
          width: 8px;
          height: 8px;

          &.active {
            width: 10px;
            height: 10px;
            background-color: #2f7df6;
          }
        }
      }
    }
  }
}
