.checkbox-all-group {

  display: flex;
  flex-direction: column;
  gap: 8px;

  &__label {
    font: normal normal 400 12px/14px 'Work Sans', sans-serif;
    color: #292B2E;
    display: block;
  }

  .ant-checkbox-wrapper > span {
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
    color: #292B2E;
  }

  .ant-checkbox-disabled+span {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .ant-form-item {
    margin-bottom: 0!important;
  }
}
