.title-tabs {
  span {
    cursor: pointer;
    width: 400px;
    font-weight: 400 !important;
    .active {
      font-weight: 600 !important;
    }
  }
}
