.sidebar-link {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;

  &__icon {
    display: flex;
  }
  &__icon.active {
    display: none;
  }

  &__label {
    color: #505762!important;
    font: normal normal 400 16px/18px 'Work Sans', sans-serif;
  }

  @media screen and (max-width: 992px){
    flex-direction: column;
    gap: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.sidebar-link.active {
  .sidebar-link__icon {
    display: none;
  }
  .sidebar-link__icon.active {
    display: flex;
  }
  .sidebar-link__label {
    color: #2F7DF6!important;
  }
}
