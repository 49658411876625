.eiq-notifications-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  .eiq-header {
    flex-direction: row !important;
    align-items: flex-start !important;
  }
}

.eiq-column-row {
  display: flex;
}


.notifications-preview-list {
  font: normal normal 400 14px/18px 'Work Sans', sans-serif;
  flex: 1;
  position: relative;
  min-height: 1px !important;

  .loading-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    display: flex;
    justify-content: center;
  }

  .loading-more-layer {
    pointer-events: none;
    background: rgba(32, 60, 94, 0.3);
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .loading-more-text {
      color: #fff;
      margin-right: 10px;
    }
  }

  * {
    transition: unset !important;
  }

  .BaseTable {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    box-shadow: unset;
  }

  .BaseTable__row {
    border-bottom: none;
  }

  .BaseTable__row-cell {
    border-right: none;
    padding-left: 0;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.notifications-preview-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font: normal normal 400 16px/24px 'Work Sans', sans-serif;
  color: #505762 !important;
  .notifications-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    border-bottom: 1px solid #C9D1DA;
    padding-bottom: 16px;
  }
  .actions {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #E1E5EA;
    border-radius: 4px;
    .filter {
      display: flex;
      align-items: center;
      gap: 14px;
    }
    .mark-all {
      .ant-btn {
        display: flex;
        svg path {
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &:hover {
          color: #69b1ff;
          svg path {
            fill: #69b1ff;
          }
        }
      }
    }
  }
  .show-all-btn {
    font: normal normal 500 22px/26px "Work Sans", sans-serif;
  }
  .notification-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .notification-icon-and-body {
      display: flex;
      gap: 12px;
      .notification-content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.ant-modal-title {
  font: normal normal 500 22px/26px 'Work Sans', sans-serif !important;
  color: #292B2E;
}

p {
  margin: 0;
}

.message-date, .message-body, .message-status {
  font: normal normal 400 16px/24px 'Work Sans', sans-serif;
}

.notification-icon {
  flex-shrink: 0;
  background-color: #E1E5EA;
  border-radius: 56px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.mobile {
    width: 32px;
    height: 32px;
    svg {
      width: 19px;
      height: 19px;
    }
  }
}

.message-body {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  user-select: none;
  &.unread {
    color: #292B2E;
    font-weight: 500;
  }
}

.read {
  color: #5396FF !important;
}

.notification-badge {
  position: relative;
  .ant-badge-dot {
    width: 8px;
    height: 8px;
    background-color: #D11508;
  }
  &.button-badge .ant-badge-dot{
    position: absolute;
    top: 6px;
    right: 6px;
  }
  &.avatar-badge .ant-badge-dot {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.message-subject {
  font: normal normal 600 16px/24px 'Work Sans', sans-serif !important;
  color: #292B2E;
  user-select: none;
}

.notifications-filter {
  .ant-select-selector {
    border-radius: 16px !important;
  }
}

.check-icon {
  width: 32px;
  height: 32px;
  background-color: #2F7DF6;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.notification-date {
  @media (max-width: 768px) {
    width: 60px !important;
    min-width: 60px !important;
    align-items: flex-start;
    .message-date {
      align-items: flex-end;
    }
  }
}