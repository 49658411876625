.eiq-login-layout {
  background: #fff;
  min-height: 100vh;
  height: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
  font: normal normal 400 14px/18px 'Work Sans', sans-serif;
  color: #292b2e;

  button.ant-btn-primary {
    background: #2f7df6;
    border-radius: 4px;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
  }

  .title {
    font: normal normal 600 24px/34px 'Work Sans', sans-serif;
    margin-bottom: 8px;
  }

  .text {
    display: flex;
    font: normal normal 400 16px/24px 'Work Sans', sans-serif;
    align-items: center;
    color: #505762;
    letter-spacing: 0.269px;
    gap: 4px;
    flex: 1;
  }

  .text-center {
    text-align: center;
    justify-content: center;
  }

  // Input improvements
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .ant-form-item {
      margin-bottom: 0;

      .ant-form-item-label {
        line-height: 1;
        display: flex;
        label {
          color: #292b2e;
          font: normal normal 400 16px/18px 'Work Sans', sans-serif;
        }
      }

      .ant-input-affix-wrapper {
        padding: 0;
        border-radius: 16px;
        background: #f6f8fa;
        input {
          border: unset;
          height: 44.5px;
        }
        .ant-input-suffix {
          padding-right: 20px;
          cursor: pointer;
        }
      }
      input {
        height: 46px;
        padding: 2px 20px 2px 16px;
        border-radius: 16px;
        background: #f6f8fa;
        color: #505762;
        font: normal normal 400 16px/24px 'Work Sans', sans-serif;
        letter-spacing: 0.269px;
      }
      .ant-form-item-required:before {
        content: unset !important;
      }
      .ant-form-item-explain-error {
        display: none !important;
      }
    }
  }

  .ant-divider {
    background: #e1e5ea;
    line-height: 1px;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .forgot-password {
    line-height: 1;
    margin-bottom: 32px;
  }

  a {
    font: normal normal 500 16px/24px 'Work Sans', sans-serif;
  }

  .action-line {
    display: flex;
    justify-content: center;
    line-height: 1;

    button {
      display: flex;
      height: 50px;
      padding: 2px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 15px;
      background: #2f7df6;

      font: normal normal 500 22px/26px 'Work Sans', sans-serif;
      color: #f6f8fa;
    }
  }

  .action-two-lines {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    > * {
      flex: 1;
    }
    > :first-child {
      margin-right: 20px;
    }
  }

  .ant-mt-16 {
    margin-top: 16px;
  }
}

.eiq-auth-wrapper {
  margin-left: 16px;
  margin-right: 16px;
  width: 454px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 12px;
  background: #ffffff;

  .auth-log {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;

    .logo {
      width: 250px;
    }
  }
}
