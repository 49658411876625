.citation-wrapper {
  @media screen and (min-width: 767px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .eiq-details-row {
      border: none;

      &.fine-amount {
        flex-grow: 2;
        .eiq-details-row__text {
          color: #e86056;
        }
      }

      &.violating-speed-row {
        flex-grow: 2;
      }

      .text {
        color: #505762;
        font: normal normal 400 16px/18px 'Work Sans', sans-serif;

        &.violating-speed {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #e86056 !important;
          gap: 8px;
        }

        &.fine {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          span {
            font: normal normal 600 24px/30px 'Work Sans', sans-serif;
            color: #292b2e;
          }
        }
      }
    }

    .eiq-image-gallery {
      order: 98;
      width: 100%;
    }
    .fine {
      order: 99;
    }

    .details {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      padding: 16px 0;

      .guestName {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .text {
        color: #505762;
        font: normal normal 400 16px/18px 'Work Sans', sans-serif;

        &.violating-speed {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #e86056 !important;
          gap: 8px;
        }

        &.fine {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          span {
            font: normal normal 600 24px/30px 'Work Sans', sans-serif;
            color: #292b2e;
          }
        }
      }

      .title {
        color: #292b2e;
        font: normal normal 400 20px/24px 'Work Sans', sans-serif;
      }
    }
  }

  .eiq-details-row {
    &.fine-amount {
      .eiq-details-row__text {
        color: #e86056;
      }
    }

    .text {
      &.violating-speed {
        font: normal normal 400 20px/24px 'Work Sans', sans-serif;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  .fine {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      min-width: 320px;
      max-width: 420px;
      width: 100%;
    }
  }

  .imagesWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .images {
      width: 673px;
      height: 416px;
    }
  }

  .text {
    color: #505762;
    font: normal normal 400 16px/18px 'Work Sans', sans-serif;

    &.violating-speed {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #e86056 !important;
      gap: 8px;
    }
  }
}
