.violationSpeed {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 8px !important;

  :global(.eiq-column__text) {
    color: #e86056 !important;
  }
}
