// TODO There are a lot of duplicated styles here (e.g. vehicles and guests are almost identical). We should refactor this file to remove the duplication
.eiq-vehicle-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}

.eiq-vehicle-add-edit {
  .eiq-content {
    .ant-form {
      width: 100%;
      height: 100%;

      .ant-col {
        flex-grow: 1;
      }
    }

    .eiq-vehicle-block {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-form {
    height: 100vh;
    width: 100%;

    .ant-col {
      flex-grow: 1;
    }
  }

  .eiq-content {
    gap: 0 !important;
    width: 100%;
    height: calc(100vh - 76px);
    overflow-y: auto;

    .ant-space {
      align-items: start;
      gap: 24px !important;
    }

    .eiq-date-picker {
      height: 46px;
      transform: translateY(-3px);
    }

    .eiq-date-range {
      transform: translateY(-3px);
    }

    .ant-picker {
      padding: 0;
    }

    .ant-picker-input {
      height: 44px;

      input {
        height: 40px;
      }
    }

    .ant-checkbox-group {
      gap: 8px;
    }

    .eiq-input {
      width: calc(50% - 12px);
    }

    .ant-btn {
      color: #E86056;
      padding: 0;
    }
  }

  .ant-space-with-uploader {
    .ant-space-item {
      max-width: 50%;

      .ant-upload {
        .ant-upload-drag {
          border: none !important;
          background: transparent !important;
          border-radius: 16px;

          .ant-upload {
            padding: 0 !important;

            .ant-upload-drag-container {
              .ant-upload-text {
                background: rgba(0, 0, 0, 0.02);
                padding: 31px 23px !important;
                border-radius: 16px;
                border: 1px dashed var(--secondary-stroke-100, #AEB5BE);
                font: normal normal 400 16px/24px 'Work Sans', sans-serif;
                color: #292B2E;

                span {
                  color: #2F7DF6;
                }
              }
            }
          }
        }

        .ant-upload-list-item {
          height: 40px;
          background-color: #F6F8FA;
          border: none;

          .ant-upload-list-item-thumbnail {
            max-height: 28px;
            max-width: 40px;

            .ant-upload-list-item-image {
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .eiq-vehicle-add-edit {
    .eiq-content {

      .ant-space {
        flex-wrap: wrap;
        gap: 0 !important;
      }

      .ant-divider {
        margin: 20px 0;
      }

      .eiq-input {
        width: 100%;
      }

      .eiq-date-range {
        min-height: 180px;
        height: 100%;
      }

      .eiq-date-picker {
        min-height: 280px;
        height: 100%;
      }

      .ant-space-with-uploader {
        .ant-space-item {
          max-width: 100%;
        }
      }
    }
  }
}

.android-photo-menu {
  margin-top: 24px;

  .ant-dropdown-menu-item {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    color: #292B2E;
    font: normal normal 400 16px/18px "Work Sans", sans-serif !important;
  }

  .ant-upload {
    font: normal normal 400 16px/18px "Work Sans", sans-serif !important;
  }

  .anticon {
    font-size: 24px !important;
    margin-right: 16px !important;
  }
}
