.eiq-edit-account-page {
  .ant-form {
    height: 100vh;
  }

  p {
    color: #292b2e;
    font: normal normal 400 16px/18px 'Work Sans', sans-serif;
    margin: 0 0 20px 0;
  }

  .eiq-content {
    gap: 0 !important;
    width: 100%;
    height: calc(100vh - 76px);
    overflow-y: auto;
  }

  .eiq-account-pin-container {
    gap: 20px !important;
    display: flex;
    flex-direction: column;

    .pin-title {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif;
    }

    .pin-note {
      font-style: italic;
    }

    .pin-item {
      display: flex;
      flex-direction: row;
      gap: 20px !important;
    }

    div {
      flex-grow: 1;
    }
  }

  .eiq-account-email-container {
    gap: 20px !important;

    div {
      flex-grow: 1;
    }
  }

  .eiq-vacation-notification-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 24px !important;

    .ant-space-item {
      flex: 1;
    }

    .eiq-vacation {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .eiq-notification-container {
    p {
      font: normal normal 400 14px/18px 'Work Sans', sans-serif;
      margin: 0 0 12px 0;
    }
    span {
      font: normal normal 400 16px/24px 'Work Sans', sans-serif;
    }
    .eiq-label {
      font: normal normal 400 16px/24px 'Work Sans', sans-serif;
    }
  }

  .eiq-phones-wrapper {
    display: flex;
    flex-direction: column;

    .eiq-phones-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      p {
        margin: 0;
      }

      .edit-cancel-btn {
        font: normal normal 400 16px / 18px 'Work Sans', sans-serif;
      }
    }

    .eiq-phones-container {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    .eiq-vacation-notification-container {
      flex-direction: column;

      .ant-space-item {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .eiq-account-pin-container {
      flex-wrap: wrap;

      .pin-item {
        flex-direction: column;
      }

      div {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .eiq-account-email-container {
      flex-wrap: wrap;

      div {
        width: 100%;
      }
    }
  }
}

@media (min-width: 993px) {
  .phones div:nth-of-type(odd) .eiq-phone-card {
    margin-right: 24px;
  }
}

.eiq-phone-card-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
