.info-field{
    display: flex;
    flex-direction: column;
    min-height: 38px;

    .info-field-lable{
        font-size: 12px;
        font-weight: 400;
        color: #505762;
        margin-bottom: 4px;
        line-height: 14px;
    }

    .info-field-value{
        font-size: 14px;
        font-weight: 400;
        color: #292B2E;
        display: flex;
        flex-direction: row;
        line-height: 18px;

        span:nth-child(1) {
            margin-right: 6px;
        }
    }

    .info-field-bold {
        font-weight: 500;
    }
}
