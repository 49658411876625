.eiq-phone-card {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-radius: 16px;
  border: 1px solid var(--secondary-stroke-80, #c9d1da);
  gap: 24px;
  width: 100%;
  .eiq-phones-inputs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;

    .eiq-phone-label {
      margin-top: 0;
      margin-bottom: 8px;
    }
    .eiq-phone-input, .eiq-account-edit-select {
      width: calc(50% - 12px);
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .eiq-phones-add-btn {
    margin-top: 20px;
    padding: 5px 0;
    background-color: transparent;
    border: none;
    align-self: center;
    font-size: 22px;
    font-weight: 500;
    max-width: 120px;
    color: var(--accent-100, #2f7df6);
    cursor: pointer;
  }

  .eiq-phone-card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &.arrows-shown {
      justify-content: space-between;
    }
    .call-order {
      display: flex;
      gap: 10px;
      align-items: center;
      .disabled path {
        opacity: 40%;
      }
    }
    .eiq-phones-delete-btn {
      cursor: pointer;
      align-self: flex-end;
    }
  }
}

