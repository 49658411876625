.form-loading {
  position: absolute;
  top: 60px;
  bottom:0;
  left: 0;
  right: 0;
  padding-bottom: 60px;
  background: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-loading-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100000;
    background: rgba(255,255,255, 0.2);
}
