.text-center {
  justify-content: center;
}

.ant-mb-32 {
  margin-bottom: 32px !important;
}

.vertical-radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vertical-checkbox-group {
  display: flex;
  flex-direction: column;
}

.notification-wrapper {
  &.ant-notification-notice-error {
    background: #e86056;
  }

  &.ant-notification-notice-success {
    background: #14c68a;
  }

  &.ant-notification-notice-warning {
    background: #ffd889;

    .ant-notification-notice-message {
      color: #b67e0b !important;
    }
  }

  &.ant-notification-notice {
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: 8px;

    .ant-notification-notice-content {
      flex: 1;

      .ant-notification-notice-with-icon {
        //display: flex;
        .ant-notification-notice-icon {
          display: flex;
          align-items: center;
          top: 8px;
        }

        .ant-notification-notice-message {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #f6f8fa;
          margin-bottom: 0;
          margin-inline-start: 40px;
          padding-right: 8px;
        }
      }
    }

    .ant-notification-notice-close {
      position: static;
      width: 16px;
      height: 16px;
      color: #ffffff;
    }

    .vertical-line {
    }

    .exclam {
      margin-left: 10px;
    }
  }
}

// Card style
.ccs-card {
  .ant-card-body {
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @media screen and (max-height: 991px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  border-radius: 8px;
}

.ccs-card-border {
  border: 2px solid #87b6ff;
  box-shadow: 0 0 0 2px rgba(135, 182, 255, 0.3);
  border-radius: 8px;
}

.ccs-card-header {
  margin-bottom: 20px;
}

.h1 {
  font: normal normal 500 18px/21px 'Work Sans', sans-serif;
  color: #292b2e;
  margin-top: 0;
  margin-bottom: 0;
}

.h3 {
  font: normal normal 500 14px/18px 'Work Sans', sans-serif;
  color: #292b2e;
  margin-top: 0;
  margin-bottom: 0;
}

// Space styles
.full-width {
  width: 100%;

  > .ant-space-item {
    width: 100%;

    > .ant-form-item .ant-picker {
      width: 100%;
    }
  }
}

.full-space {
  width: 100%;
}

// Custom Mixin
@mixin scroll {
  &::-webkit-scrollbar {
    height: 38px;
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 10px transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    box-shadow: inset 0 0 10px 10px #d8d8d8;
    border-top: solid 5px transparent;
    border-left: solid 7px transparent;
    border-bottom: solid 10px transparent;
    border-right: solid 7px transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px rgb(170, 169, 169);
  }
}

// Modal
.custom-modal,
.custom-full-modal,
.ccs-configuration {
  .ant-modal-footer {
    visibility: hidden;
  }

  .footer {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    padding-top: 12px;
    z-index: 2;
  }

  .ant-modal-header {
    margin-bottom: 22px;

    .ant-modal-title {
      font: normal normal 500 14px/18px 'Work Sans', sans-serif;
    }
  }

  .ant-modal-content {
    max-height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 0 10px 15px;
    overflow: hidden;

    .ant-modal-body {
    }

    .ant-modal-body {
      overflow-y: auto;
      overflow-x: visible;
      padding-right: 17px;
      padding-left: 2px;
      @include scroll;
    }
  }

  .ant-modal-footer {
    justify-content: center;
  }

  .ant-input-affix-wrapper-sm,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #aeb5be;
  }

  .ant-form-item {
    margin-bottom: 10px;

    .ant-form-item-label {
      line-height: 1;
      max-height: 22px;

      label {
        font: normal normal 400 12px/14px 'Work Sans', sans-serif;
      }
    }

    .ant-form-item-control-input {
      min-height: 30px;
    }

    input.ant-input {
      font: normal normal 400 14px/18px 'Work Sans', sans-serif;
      padding: 5px 4px 5px 4px;
    }

    textarea {
      padding: 5px 4px 5px 4px;
    }

    input,
    textarea,
    .ant-picker {
      color: #292b2e;
      border-color: #aeb5be;
      font: normal normal 400 14px/18px 'Work Sans', sans-serif;
    }

    *::placeholder {
      color: #505762 !important;
      font: normal normal 400 14px/18px 'Work Sans', sans-serif;
    }
  }

  //Date picker
  .ant-picker {
    padding: 5px 4px 5px 4px;
    color: #292b2e;
    border-color: #aeb5be;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
  }
}

.custom-full-modal {
  .ant-modal {
    width: calc(100vw - 48px) !important;
  }
}

// Select
.ccs-select {
  .ant-select-selection-search {
    inset-inline-start: 3px !important;
  }

  .ant-select-selection-item {
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;

    * {
      line-height: 18px !important;
    }
  }

  .ant-form-item-control-input {
    line-height: 18px !important;
  }

  .ant-form-item-control {
    //max-height: 30px;
  }
  .ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border: 1px solid #ff7875 !important;
  }

  .ant-select-selector {
    padding-left: 2px !important;
    padding-right: 2px !important;
    border: 1px solid #aeb5be !important;
    border-radius: 4px !important;

    .ant-select-selection-item {
      .ant-tag {
        overflow: unset;
        white-space: nowrap;
        text-overflow: unset;
        margin-right: 4px;
        //transform: translateY(15%);
      }

      // display: inline-flex;
      //align-items: center;
    }
  }
}

.ant-select-open {
  .ant-select-selection-item {
    color: #505762 !important;
  }
}

.ccs-select-options {
  .ant-select-item-option-content {
    .ant-tag {
      overflow: unset;
      white-space: nowrap;
      text-overflow: unset;
      margin-right: 4px;
    }

    //display: inline-flex!important;
    //align-items: center!important;
  }
}

// Margin/Padding
.ant-mr-8 {
  margin-right: 8px;
}

.ant-mr-10 {
  margin-right: 10px;
}

//labels
.eiq-label {
  border-radius: 21px;
  padding: 3px 6px;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #d1e3ff;
  color: #292b2e;

  &.primary {
    background-color: #d1e3ff;
    color: #292b2e;
  }

  &.warning {
    background-color: #ffd889;
    color: #292b2e;
  }

  &.danger {
    background-color: #e86056;
    color: #f6f8fa;
  }

  &.emergency {
    background-color: #e86056;
    color: #f6f8fa;
  }

  &.secondary {
    background-color: #c1ffea;
    color: #292b2e;
  }
}

.eiq-tag {
  color: #292b2e !important;
  border-radius: 21px;
  height: 20px;
  box-sizing: border-box;
  padding: 3px 6px;
  font: normal normal 400 12px/14px 'Work Sans', sans-serif;

  &.expired {
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: #ffa39e;
  }
}

.go-back {
  color: #292b2e;
  font: normal normal 500 18px/21px 'Work Sans', sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;

  .hotkey-link__label {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .cc-key {
    width: unset !important;
  }
}

.no-wrap {
  word-break: keep-all;
  white-space: nowrap;
}

.eiq-button {
  width: 159px;
}

.eiq-divider.ant-divider {
  background: #e1e5ea;
  margin-top: -12px;
  margin-bottom: 12px;
}

.page-header {
  margin-bottom: 20px;
}

.content-title {
  margin-bottom: 15px;
}

.main-title {
  margin: 0;
  padding: 0;
  color: #292b2e;
  font: normal normal 500 18px/21px 'Work Sans', sans-serif !important;
  display: inline-flex;
  align-items: center;
}

.sub-title {
  margin: 0;
  padding: 0;
  color: #505762;
  font: normal normal 400 12px/14px 'Work Sans', sans-serif !important;
  display: inline-flex;
  align-items: center;
}

.css-primary-brn {
  padding: 2px 8px;
}

.mobile-footer {
  margin-top: 24px;
  padding: 16px 0 24px 0;
}

.mobile-fixed-footer {
  background: white;
  padding: 16px 0 24px 0;
  height: 72px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mb-12 {
  margin-bottom: 12px;
}

.d-flex {
  display: flex;
  flex: 1;
}

.row-h-50 {
  min-height: calc(50vh - 47px); // 50vh - (header+contentPadding) / 2
  //min-height: 465px;

  .ant-col {
    max-height: 100%;
  }

  .ant-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }
}

.row-h-100-mob-tab {
  @media screen and (max-width: 991px) {
    height: calc(100vh - 85px - 50px); // 100vh - (header+contentPadding) - fixed footer
  }
}

.with-mob-tab-action {
  .row-h-100-mob-tab {
    @media screen and (max-width: 991px) {
      height: calc(100vh - 85px - 122px); // 100vh - (header+contentPadding) - fixed footer
    }
  }

  .open-close-container {
    bottom: 72px;
  }
}

.guest-list__with-mob-tab-action {
  .row-h-100-mob-tab {
    @media screen and (max-width: 991px) {
      height: calc(100vh - 85px - 200px); // 100vh - (header+contentPadding) - fixed footer
    }
  }

  .open-close-container {
    bottom: 150px;
  }
}

// Card
.header-row {
  // margin-bottom: 20px;
}

.row-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

// Custom buttons style
.ant-btn.active-filter {
  background: rgba(47, 125, 246, 0.3);
  border-color: transparent;
}
.ant-btn.active-filter-no-text {
  svg path {
    stroke: #2f7df6;
  }
}
.primary-btn.ant-btn-primary {
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font: normal normal 400 14px/16px 'Work Sans', sans-serif;

  svg path {
    stroke: white;
  }

  .ant-btn-icon {
    margin-right: 0 !important;
  }
}

.secondary-btn.ant-btn-default {
  color: #2f7df6;
  border-color: #e5efff;
  background: #e5efff;
  padding: 2px 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font: normal normal 400 14px/16px 'Work Sans', sans-serif;

  .ant-btn-icon {
    margin-right: 0 !important;
  }

  svg path {
    stroke: #2f7df6;
  }

  &:not(:disabled):hover {
    border-color: #87b6ff;
  }

  &:not(:disabled):focus {
    color: #2f7df6;
    border-color: #87b6ff;
    background: #e5efff;
  }

  &:not(:disabled):active {
    color: #2f7df6;
    border-color: #d1e3ff;
    background: #d1e3ff;
  }
}

.important-btn.ant-btn-default {
  color: #f6f8fa;
  background: #e86056;
  padding: 2px 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font: normal normal 400 14px/16px 'Work Sans', sans-serif;
  border: none;

  .ant-btn-icon {
    margin-right: 0 !important;
  }

  svg path {
    stroke: #f6f8fa;
  }

  &:not(:disabled):focus {
    box-shadow: 0px 0px 0px 2px rgba(13, 105, 212, 0.2);
    background: #ff7970;
    color: #f6f8fa;
  }

  &:not(:disabled):hover {
    background: #ff7970;
    color: #f6f8fa;
  }
}

.breadcrumb-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    cursor: pointer;
  }

  .ant-breadcrumb-link {
    color: #505762;
    font: normal normal 400 18px/21px 'Work Sans', sans-serif !important;
    cursor: pointer;
  }

  ol {
    li:last-child {
      .ant-breadcrumb-link {
        color: #292b2e;
        font: normal normal 500 18px/21px 'Work Sans', sans-serif !important;
        cursor: default;
      }
    }

    li:first-child {
      .ant-breadcrumb-link {
        cursor: pointer;
      }
    }
  }
}

.blue-alert {
  border-radius: 4px;
  border: 1px solid #d1e3ff;
  background: #e5efff;
  display: flex;
  align-items: center;
  font: normal normal 400 14px/18px, 'Work Sans', sans-serif;
  color: #2f7df6;
  min-height: 128px;
}

.ccs-text {
  font: normal normal 400 14px/18px, 'Work Sans', sans-serif;
}

.phone-number {
  color: #292b2e;
}

.ant-form-item-explain-error {
  display: none !important;
}

//Custom ant image style
.ant-image-mask-info {
  display: flex;
  font-size: 0;
  .anticon-eye {
    font-size: 14px;
    margin-inline-end: 0 !important;
    display: flex;
    align-items: center;
  }
}

.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

.ant-image-img {
  object-fit: contain;
}

.ant-image-preview-img {
  max-width: 50% !important;
  max-height: 50% !important;
  transform: none !important;
}

// fix iPhone overlap and other cases with space in th top of screen
.ant-notification {
  margin-top: 32px;
}

.btn-with-text {
  font-size: 16px !important;
  line-height: 24px !important;
  color: #505762 !important;
  gap: 4px;
  border-radius: 100px !important;
  padding: 2px 8px !important;
}

.bold-text {
  font-weight: 600;
}

.round-primary.disabled-button {
  background-color: rgb(197, 204, 212);
  cursor: not-allowed;
}
