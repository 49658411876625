.mainSpinnerWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;

    div {
        width: 70px;
        height: 70px;
    }
}
