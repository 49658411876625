.cc-key {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  width: 16px;
  height: 16px;
  background: #F6F8FA;
  border: 1px solid #AEB5BE;
  border-radius: 3px;
  flex-grow: 0;

  font: normal normal 400 12px/14px 'Work Sans';
  text-transform: uppercase;

  color: #505762;

}
