.confirmation-modal {
    .ant-modal-title {
        font: normal normal 600 24px/33.6px 'Work Sans', sans-serif !important;
        color: #505762;
    }

    .ant-modal-body {
        p {
            font: normal normal 400 16px/24px 'Work Sans', sans-serif !important;
            color: #505762;
            span {
               color: #292B2E;
                font-weight: 500;
            }
        }
    }

    .btn-container {
        margin-top: 40px;

        Button {
            display: flex !important;
            justify-content: center;
            align-items: center;
            height: 50px;
            border-radius: 20px;
            font: normal normal 500 22px/26px 'Work Sans', sans-serif  !important;
        }
    }

    .ant-modal-footer {
        display: none;
    }
}