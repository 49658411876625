.fast-access-pass {
  max-width: 556px;
  margin: auto;
  padding: 24px; /* Replace with 24px 24px 56px when the "Add to Wallet" button is added */
  .row {
    text-align: center;
    width: 100%;
    .col {
      width: 100%;
      .guest-name {
        font: normal normal 600 24px/34px 'Work Sans', sans-serif;
        color: #292b2e;
        margin-bottom: 8px;
      }
      .guest-text {
        font: normal normal 400 16px/24px 'Work Sans', sans-serif;
        margin-bottom: 16px;
        color: #505762;
        justify-content: center;
      }

      .fast-access-wrapper {
        display: flex;
        justify-content: center;
        margin: 15px 0;
      }
    }
  }

  .fast-access-qr-code-border {
    background-image: url('../../assets/icons/eiq-connect/qr-code-frame.png');
    background-size: cover;
    width: 216px;
    height: 216px;
    padding: 8px;
    box-sizing: border-box;
  }

  .title {
    color: #505762;
    font: normal normal 400 16px/18px 'Work Sans', sans-serif;
  }

  .text {
    font: normal normal 500 20px/24px 'Work Sans', sans-serif;
    color: #292b2e;
  }

  .logo-wrapper {
    text-align: center;
    margin-bottom: 40px;
    .logo {
      width: 288px;
      height: 64px;
    }
  }
  .ant-divider-horizontal {
    margin: 12px 0;
  }
  a {
    text-decoration: none;
    color: #2f7df6;
  }
}
