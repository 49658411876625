.notification-modal {
  .ant-modal .ant-modal-content {
    padding: 32px 24px;

    @media (max-width: 500px) {
      padding: 16px;
    }
  }
  .ant-modal {
    max-width: 650px;
    width: calc(100% - 3em) !important;

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      &-form-container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        & h1 {
          margin: 0px;
          font: normal normal 600 24px/34px 'Work Sans';
          text-align: center;
        }

        & p,
        label {
          margin: 0px;
          font: normal normal 400 16px/24px 'Work Sans';
        }

        .vertical-checkbox-group {
          gap: 8px;
        }

        &-text {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      .modal-footer {
        max-width: 480px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-btn {
          font: normal normal 500 22px/26px 'Work Sans';
          border: none;
          box-shadow: none;
          border-radius: 15px;
          height: 50px;
          max-width: 234px;
          width: 100%;
        }
        .cancel-btn {
          color: #1677ff;
        }

        .primary-btn:disabled {
          background-color: #c1d8fc;
          color: white;
        }

        @media (max-width: 500px) {
          flex-direction: column-reverse;
          gap: 12px;
          .ant-btn {
            max-width: 100%;
          }
        }
      }
    }
  }
}
