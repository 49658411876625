.eiq-panel {
  height: 100vh;
  font: normal normal 400 14px/18px 'Work Sans';
  color: #505762 !important;
  background: #2b4267 !important;

  .ant-layout {
    min-height: 100%;
  }

  .ant-form {
    display: flex;
    flex-direction: column;

    .ant-form-item {
      margin-bottom: 0;

      .ant-form-item-label {
        line-height: 1;
        max-height: 22px;
        display: flex;

        label {
          color: #292b2e;
          font: normal normal 400 16px/18px 'Work Sans', sans-serif;
        }
      }

      .ant-input-affix-wrapper {
        padding: 0;
        border-radius: 16px;
        border: 1px solid #e1e5ea;
        background: #f6f8fa;

        input {
          border: unset;
          height: 44.5px;
        }

        .ant-input-suffix {
          padding-right: 20px;
          cursor: pointer;
        }
      }

      input,
      .ant-select-selector {
        height: 46px;
        padding: 2px 20px 2px 16px;
        border-radius: 16px;
        background: #f6f8fa;
        color: #505762;
        font: normal normal 400 16px/24px 'Work Sans', sans-serif;
        letter-spacing: 0.269px;
      }

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }

      .ant-form-item-explain-error {
        display: none !important;
      }
    }
  }

  .ant-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font: normal normal 500 22px/26px 'Work Sans', sans-serif;
    padding: 2px 16px;
    border-radius: 15px;

    &:not(.round-primary) {
      height: 46px;
    }
  }

  .ant-picker {
    background: #f6f8fa;
    border: 1px solid #e1e5ea;
    border-radius: 16px;
    padding: 0;
    span {
      padding-right: 4px;
    }
  }

  &__sider {
    box-sizing: border-box;
    min-width: 238px !important;
    background: rgba(192, 203, 214, 0.2) !important;
    backdrop-filter: blur(16px) !important;
    max-width: 238px !important;
    width: 238px !important;
    padding: 0 !important;

    .eiq-panel__logo {
      width: 170px;
    }

    // Body
    .ant-layout-sider-children {
      box-sizing: border-box;
      display: flex !important;
      padding: 28px 0 !important;
      flex-direction: column !important;
      align-items: center !important;
      gap: 32px !important;
      flex-shrink: 0;
    }
  }

  // Links
  .eiq-panel__menu.ant-menu {
    width: 100%;
    padding: 0;
    background: transparent;

    .ant-menu-item {
      margin: 0 !important;
      width: 100%;
      padding: 0 24px !important;
      border-radius: 0;
      background: transparent !important;
      height: 48px !important;
      line-height: 1 !important;
      display: flex;
      flex: 1;

      .ant-menu-title-content {
        display: flex;
        flex: 1;
      }
    }
  }

  .eiq-panel__content {
    background: #2b4267;
    display: flex;
    flex-direction: column;
    height: 100vh;

    @media screen and (max-width: 992px) {
      height: unset;
    }

    .eiq-header {
      display: flex;
      padding: 10px 24px 10px 40px;
      margin-top: 10px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      justify-content: space-between;
      position: relative;

      @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: stretch;
        .eiq-top-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .eiq-logo {
            width: 126px;
            height: 28px;
          }
        }
        .eiq-title {
          order: 3;
        }
      }

      @media screen and (max-width: 767px), screen and (max-height: 767px) and (orientation: landscape) {
        padding: 10px 16px;
      }

      .eiq-back-btn {
        color: #f6f8fa;
        font: normal normal 500 20px/30px 'Work Sans', sans-serif;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .eiq-bottom-header {
        display: flex;
        align-items: center;
      }

      &:not(.main-page) {
        .eiq-title {
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
        }
      }
    }

    .eiq-title {
      color: #f6f8fa;
      font: normal normal 600 24px/34px 'Work Sans', sans-serif;
      margin: 0;
      white-space: nowrap;
      svg {
        cursor: pointer;
        margin-right: 10px;
      }
    }

    .eiq-action {
      button {
        color: #fff;
      }
    }

    .eiq-active-filter {
      background-color: #5396ff;
      border-color: #5396ff;
      svg path {
        fill: #f6f8fa;
      }
    }

    .eiq-content {
      display: inline-flex;
      padding: 24px 24px 24px 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
      border-radius: 32px 32px 0 0;
      background: #fff;
      flex: 1;
      overflow-x: hidden;

      @media screen and (max-width: 992px) {
        padding: 16px 24px 0 24px;
      }

      @media screen and (max-width: 767px), screen and (max-height: 767px) and (orientation: landscape) {
        padding: 16px 16px 0 16px;
      }
    }

    .eiq-footer-actions {
      display: flex;
      padding: 16px 24px 24px 24px;
      align-items: center;
      justify-content: center;
      gap: 16px;
      background: #fff;
      width: 100%;

      button {
        width: 100%;
        max-width: 354px;
      }
    }
  }

  .eiq-panel__footer {
    display: flex;
    flex-direction: column;

    .eiq-panel__menu {
      display: flex;
      max-height: 68px;
    }

    .eiq-panel__home-indicator {
      display: flex;
      justify-content: center;
      height: 34px;
      padding-bottom: 8px;
      align-items: flex-end;
    }
  }

  // Buttons
  .round-primary {
    border-radius: 50%;
    border: 1px solid #d1e3ff;
    background: #d1e3ff;

    &:not(:disabled):hover {
      border-color: #d1e3ff;
    }

    &:not(:disabled):focus {
      border-color: #d1e3ff;
    }

    &:not(:disabled):active {
      border-color: #d1e3ff;
    }
  }

  .eiq-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;

    &__title {
      color: #292b2e;
      font: normal normal 400 20px/24px 'Work Sans', sans-serif;
    }

    &__text {
      color: #505762;
      font: normal normal 400 14px/21px 'Work Sans', sans-serif;
    }
  }

  .eiq-details-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    padding: 16px 0;
    border-bottom: 1px solid #c9d1da;

    &__title {
      color: #505762;
      font: normal normal 400 16px/18px 'Work Sans', sans-serif;
    }

    &__text {
      color: #292b2e;
      font: normal normal 400 20px/24px 'Work Sans', sans-serif;
    }
  }

  .eiq-tag {
    height: 30px;
    font: normal normal 400 16px/24px 'Work Sans', sans-serif;
    letter-spacing: 0.269px;
    color: #292b2e !important;
  }

  .info-field {
    .info-field-lable {
      color: #505762;
      font: normal normal 400 14px/21px 'Work Sans', sans-serif;
    }

    .info-field-value {
      color: #292b2e;
      font: normal normal 500 16px/24px 'Work Sans', sans-serif;
      letter-spacing: 0.269px;
    }
  }

  .eiq-table {
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
    flex: 1;
    position: relative;
    min-height: 1px !important;

    .loading-layer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      display: flex;
      justify-content: center;
    }

    .loading-more-layer {
      pointer-events: none;
      background: rgba(32, 60, 94, 0.3);
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px 15px;
      border-radius: 10px;
      display: flex;
      align-items: center;

      .loading-more-text {
        color: #fff;
        margin-right: 10px;
      }
    }

    * {
      transition: unset !important;
    }

    .BaseTable {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      box-shadow: unset;
    }

    .BaseTable__row {
      border-bottom: 1px solid #c9d1da;
    }

    .BaseTable__row-cell {
      border-right: none;
      padding-left: 0;
      padding-right: 12px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .eiq-guests-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .eiq-terms-page {
    & p {
      margin: 0;
    }
    & ul {
      margin: 4px;
    }
    & h3 {
      margin-bottom: 8px;
    }
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;

    .eiq-content {
      padding: 30px;
    }
  }

  .eiq-display-none {
    position: absolute !important;
    z-index: -100 !important;
  }

  .display-none {
    display: none;
  }

  .checkbox-all-group {
    &__label {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif !important;
    }

    .ant-checkbox-wrapper > span {
      font: normal normal 400 16px/24px 'Work Sans', sans-serif;
      color: #292b2e;
      display: flex;
      height: 100%;
      align-items: center;
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  .hotkey-input.search-filter {
    .ant-input-affix-wrapper {
      border-radius: 16px;

      input {
        font: normal normal 500 16px/24px 'Work Sans', sans-serif;
        letter-spacing: 0.269px;
      }
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      border: 1px solid #2f7df6;
      background: #f6f8fa;
    }

    @media screen and (max-width: 992px) {
      max-width: unset;
      margin-bottom: 16px;
    }
  }

  .eiq-search-result {
    font: normal normal 400 16px/18px 'Work Sans', sans-serif;
    color: #292b2e;
  }
}

.eiq-panel.model-iphone {
  .eiq-panel__content {
    .eiq-header {
      margin-top: 50px !important;
      // padding-top: 50px!important;
    }
  }
}

.eiq-add-edit-page {
  height: 100vh;
  .eiq-content {
    gap: 0 !important;
    width: 100%;
    height: calc(100vh - 76px);
    overflow-y: auto;
  }
}
