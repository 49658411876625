.timer {
  color: #1677ff;
  font-weight: 500;
}

.button {
  width: 100%;
}

.returnBack {
  margin-top: 0 !important;

  .resendBtn {
    font-size: 16px;
    font-weight: 500;
  }
}
